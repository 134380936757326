/* eslint-disable no-new */
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import {
    Swiper, Pagination, Navigation, EffectFade
} from 'swiper';

import 'lightcase/vendor/jQuery/jquery.events.touch.js';
import 'lightcase';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

Swiper.use([Pagination, Navigation, EffectFade]);

(
    function ($) {
        var Site = {
            $body: $('html'),
            $searchContainer: $('.header .header__search'),
            $searchSuggestionsContainer: $('.header .search-suggestions'),

            $animateOnScroll: $('[data-animate]'),
            $animateFixedOnScroll: $('[data-animate-fixed]'),

            $lightCase: $('a[data-rel^=lightcase]'),
            $socialShare: $('.social-share'),

            $alert: $('[data-modal-target="alert-overlay"]'),

            $slider: $('.swiper--slider'),
            $sliderProducts: $('.swiper--products-slider'),

            $responsiveTables: $('table:not(.not-responsive)'),
            $responsiveEmbedVideos: $('iframe[src*="youtube"], iframe[src*="vimeo"]'),

            /**
             * Toggle the search form in the header.
             *
             * @param element
             */
            onToggleSearchForm: function (element) {
                var $input = $('input', this.$searchContainer);

                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-search-active');

                if ($input.length) {
                    if (this.$body.hasClass('primary-search-active')) {
                        setTimeout($.proxy(function () {
                            $input.focus();
                        }, this), 500);
                    } else {
                        $input.blur();
                    }
                }

                return false;
            },

            /**
             * Check the search form for an empty value.
             *
             * @param element
             */
            onSubmitSearchForm: function (element) {
                var $input = $('input', $(element.currentTarget));

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    alert($(element.currentTarget).attr('data-alert'));

                    return false;
                }

                return true;
            },

            /**
             * Check the search form suggestions.
             *
             * @param element
             */
            onChangeSearchForm: function (element) {
                var value       = $(element.currentTarget).val();
                var $form       = $(element.currentTarget).parent('.form-search');
                var $container  = $('.search-suggestions', $form);

                if ($container.length) {
                    $container.empty().removeClass('active');
                } else {
                    $container  = $('<ul class="search-suggestions" />').appendTo($form);
                }

                if (value.length >= 3) {
                    $.ajax({
                        url         : '/assets/components/simplesearch/connector.php?action=web/autosuggestions&search=' + value,
                        dataType    : 'JSON',
                        complete    : $.proxy(function (result) {
                            var $suggestions = [];

                            if (result.responseJSON.results.length) {
                                $(result.responseJSON.results).each(function (idx, suggestion) {
                                    $suggestions.push($('<li>').append(
                                        $('<a href="#" title="' + suggestion + '">' + suggestion + '</a>').bind('click', $.proxy(function (event) {
                                            $('input', this.$searchContainer).val($(event.currentTarget).attr('title'));

                                            this.$searchContainer.submit();

                                            return false;
                                        }, this))
                                    ));
                                });
                            }

                            if ($suggestions.length > 0) {
                                $container.html($suggestions).addClass('active');
                            } else {
                                $container.html($suggestions).removeClass('active');
                            }
                        }, this)
                    });
                }
            },

            /**
             * Toggle the main navigation.
             *
             * @param element
             */
            onToggleNavigation: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-navigation-active');

                $('.nav-toggle').attr('aria-expanded', (_, attr) => attr === 'false' ? 'true' : 'false');

                return false;
            },

            /**
             * Toggle sub navigation in the main navigation.
             *
             * @param element
             */
            onToggleSubNavigation: function (element) {
                $(element.currentTarget).parent('li').toggleClass('subnav-active');

                $(element.currentTarget).find('> ul').stop().slideToggle();

                $('.toggle-subnav').attr('aria-expanded', (_, attr) => attr === 'false' ? 'true' : 'false');

                return false;
            },

            /**
             * Toggle social share.
             *
             * @param element.
             */
            onToggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param element.
             */
            onHandleSocialShareUrl: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Click hash.
             *
             * @param element
             */
            onHandleHash: function (element) {
                var $element    = $('#' + element.currentTarget.hash.substr(1));
                var $element2   = $('[data-anchor="' + element.currentTarget.hash.substr(1) + '"]');

                if ($element.length) {
                    $('html, body').animate({
                        scrollTop : $element.offset().top - 50
                    }, 500);
                } else if ($element2.length) {
                    $('html, body').animate({
                        scrollTop : $element2.offset().top - 50
                    }, 500);
                }
            },

            /**
             * Toggle modal view
             */
            onToggleModal: function (element) {
                var $element    = $(element.currentTarget);
                var $container  = $('[data-modal-container]');
                var $target     = $('[data-modal-target="' + $element.data('modal') + '"]');

                if ($target.length) {
                    this.$body.addClass('modal-active');

                    $container.append($target.clone(true)).addClass('active');
                } else {
                    this.$body.removeClass('modal-active');

                    $container.empty().removeClass('active');
                }

                return $element.attr('href') !== '#';
            },

            /**
             * Toggle FAQ question.
             *
             * @param element
             */
            onToggleFaqQuestion: function (element) {
                $(element.currentTarget).parents('[data-faq-question]').toggleClass('active');

                return false;
            },

            /**
             * Toggle Filter group.
             *
             * @param element
             */
            onToggleFilterGroup: function (element) {
                // check if there is an element with class filtergroup and is active

                $(element.currentTarget).parents('.filter-group').find('.filter-list').toggleClass('active');
                return false;
            },

            /**
             * Init Lightcase.
             */
            initLightCase: function () {
                this.$lightCase.lightcase({
                    showSequenceInfo        : false,
                    fullscreenModeForMobile : true,
                    swipe                   : true,
                    showTitle               : false
                });
            },

            /**
             * Init the default slider.
             */
            initSlider: function () {
                this.$slider.each(function (index, slider) {
                    var $slider         = $(slider);

                    new Swiper($slider[0], {
                        effect          : 'fade',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
            },

            /**
             * Init the products slider.
             */
            initSliderProducts: function () {
                this.$sliderProducts.each(function (index, slider) {
                    var $slider = $(slider),
                        $sliderNavigation = $(slider).closest('[data-animate]');

                    new Swiper($slider[0], {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $sliderNavigation)[0],
                            prevEl          : $('.swiper-button-prev', $sliderNavigation)[0]
                        }
                    });
                });
            },

            /**
             * Submit filter form for vacs and team overview
             *
             * @param element
             */
            onSelectDropdown: function (element) {
                $(element.currentTarget).parents('form').trigger('submit');
            },

            /**
             * Generate fields based on amount of medicin selected.
             *
             * @param element
             */
            onAddMedicin: function (element) {
                var existing = $('.medicin-item').length;
                var medicins = parseInt($(element.currentTarget).val(), 10);
                var counter = 0;

                if (medicins > existing) {
                    counter = medicins - existing;

                    $('.medicin-item-hidden').each(function (rowIdx) {
                        if (rowIdx < counter) {
                            $(this).removeClass('medicin-item-hidden');
                            $(this).addClass('medicin-item');
                        }
                    });
                }
            },

            /**
             * Init the hash of the URL.
             */
            initHash: function () {
                var hash = window.location.hash.substr(1);

                if (hash) {
                    var $element    = $('#' + window.location.hash.substr(1));
                    var $element2   = $('[data-anchor="' + window.location.hash.substr(1) + '"]');

                    if ($element.length) {
                        $('html, body').animate({
                            scrollTop : $element.offset().top - 50
                        }, 500);
                    } else if ($element2.length) {
                        $('html, body').animate({
                            scrollTop : $element2.offset().top - 50
                        }, 500);
                    }
                }
            },

            /**
             * Init the responsive tables.
             */
            initResponsiveTables: function () {
                this.$responsiveTables.each(function (i, table) {
                    $(table).wrap('<div class="table-wrapper" />');
                });
            },

            /**
             * Init the responsive embed videos.
             */
            initResponsiveEmbedVideos: function () {
                this.$responsiveEmbedVideos.each(function (i, video) {
                    $(video).wrap('<div class="video-wrapper" />').wrap('<div class="video-wrapper__inner" />');
                });
            },

            /**
             * Init animate scroll
             */
            initAnimateScroll: function () {
                var sections = gsap.utils.toArray(Site.$animateOnScroll);
                var sectionsNotLast = sections.slice(0, -1);
                var lastSection = sections.pop();

                ScrollTrigger.create({
                    start       : 0,
                    onUpdate    : ({ direction, progress }) => {
                        if (progress === 0) {
                            this.$body.removeClass(['scroll-up', 'scroll-down']);
                        } else if (direction === 1) {
                            this.$body.removeClass('scroll-up').addClass('scroll-down');
                        } else if (direction === -1) {
                            this.$body.removeClass('scroll-down').addClass('scroll-up');
                        }
                    }
                });

                /* Animate sections */
                sectionsNotLast.forEach((section, index) => {
                    if (index > 0) {
                        gsap.set(section, {
                            opacity : 0,
                            y       : 50
                        });

                        gsap.to(section, {
                            scrollTrigger   : {
                                trigger         : section,
                                start           : 'top 80%'
                            },
                            y               : 0,
                            opacity         : 1,
                            duration        : 1
                        });
                    }
                });

                if (sections > 1) {
                    gsap.set(lastSection, {
                        opacity : 0,
                        y       : 50
                    });

                    gsap.to(lastSection, {
                        y               : 0,
                        opacity         : 1,
                        duration        : 1,
                        scrollTrigger: {
                            trigger: lastSection,
                            start: 'bottom bottom'
                        }
                    });
                }

                /* Animate 'sticky' sections */
                gsap.utils.toArray(this.$animateFixedOnScroll).forEach((section) => {
                    gsap.timeline({
                        scrollTrigger   : {
                            trigger         : section,
                            endTrigger      : 'html',
                            start           : 'bottom top',
                            end             : 'bottom top',
                            toggleClass     : 'fixed'
                        }
                    });
                });
            },

            /**
             * Trigger alert overlay
             */
            initAlertOverlay: function () {
                if (this.$alert.length > 0) {
                    var $alert = this.$alert.clone(true);

                    $('[data-modal]', $alert).on('click', function () {
                        document.cookie = 'alert-overlay=' + JSON.stringify({
                            value   : 'accepted',
                            date    : (new Date()).toUTCString()
                        }) + '; path=/';
                    });

                    $('[data-modal-container]').append($alert).addClass('active');
                }
            },

            /**
             * Init contactform
             */
            initContactForm: function () {
                var $form = $('form#form-3'),
                    $inTreatment = $form.find('input[type="radio"][name="field_128"]'),
                    $parentInTreatment = $inTreatment.parents('.form-group');

                if ($parentInTreatment.length > 0) {
                    $parentInTreatment.addClass('form-group--in-treatment');

                    if ($inTreatment.is(':checked') && $inTreatment.val() === 'Ja') {
                        $parentInTreatment.removeClass('form-group--in-treatment');
                    }

                    $inTreatment.on('change', function (element) {
                        var value = $(element.currentTarget).val();

                        if (value === 'Ja') {
                            $parentInTreatment.removeClass('form-group--in-treatment');
                        } else if (!$parentInTreatment.hasClass('form-group--in-treatment')) {
                            $parentInTreatment.addClass('form-group--in-treatment');
                        }
                    });
                }
            },

            /**
             * Init scroll to top button
             */
            initScrollToTopButton: function () {
                const scrollTopBtn = document.getElementById('scrollTopBtn');
                function toggleScrollButton() {
                    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                        scrollTopBtn.style.display = 'block';
                    } else {
                        scrollTopBtn.style.display = 'none';
                    }
                }
                window.onscroll = function () {
                    toggleScrollButton();
                };

                scrollTopBtn.addEventListener('click', function () {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                });
            },

            /**
             * Init popup based on URL parameter
             */
            initPopupByUrlParam: function () {
                function getUrlParameter(name) {
                    name = name.replace(/[\[\]]/g, "\\$&");
                    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                        results = regex.exec(window.location.href);
                    if (!results) return null;
                    if (!results[2]) return '';
                    return decodeURIComponent(results[2].replace(/\+/g, " "));
                }
            
                if (getUrlParameter('caggb') === '1') {
                    const popupElement = document.getElementById('caggb-popup');
                    const overlay = document.getElementById('caggb-overlay');
                    const closeButton = document.getElementById('caggb-close');
                    
                    if (popupElement) {
                        popupElement.style.display = 'block';
                    }
                    if (overlay) {
                        overlay.style.display = 'block';
                    }
                    if (closeButton) {
                        closeButton.addEventListener('click', function () {
                            popupElement.style.display = 'none';
                            overlay.style.display = 'none';
                        });
                    }
                }
            },            

            /**
             * Init call.
             */
            init: function () {
                this.initAnimateScroll();

                this.initLightCase();

                this.initSlider();
                this.initSliderProducts();

                this.initHash();
                this.initResponsiveTables();
                this.initResponsiveEmbedVideos();

                this.initAlertOverlay();

                this.initContactForm();
                this.initScrollToTopButton();
                this.initPopupByUrlParam();
            }
        };

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchForm')
        }, '[data-search-toggle]');

        $(document).on({
            submit: $.proxy(Site, 'onSubmitSearchForm')
        }, '.header .form-search');

        $(document).on({
            keyup: $.proxy(Site, 'onChangeSearchForm')
        }, 'header .form-search input[type="text"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleNavigation')
        }, '[data-nav-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSubNavigation')
        }, '.has-subnav .toggle-subnav');

        $(document).on({
            click: $.proxy(Site, 'onToggleSocialShare')
        }, '[data-social-share-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onHandleSocialShareUrl')
        }, '[data-social-share-url]');

        $(document).on({
            click: $.proxy(Site, 'onHandleHash')
        }, '.richtext a[href*="#"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleModal')
        }, '[data-modal]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFaqQuestion')
        }, '[data-faq-question-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFilterGroup')
        }, '.filter-btn');

        $(document).on({
            change: $.proxy(Site, 'onSelectDropdown')
        }, '[data-select-dropdown]');

        $(document).on({
            change: $.proxy(Site, 'onAddMedicin')
        }, '[name="medicincount"]');

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
